.content {
  :global {
    .DialogSection-root {
      .DialogSection-title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.submitButton {
  height: 70px;
}
