.calendarContainer {
  position: relative;
  .currentDay {
    font-size: 24px;
    line-height: 28px;
    font-weight: 800;
  }
}

.viewSelection {
  margin-right: 8px;

  &:before {
    content: none !important;
  }

  :global {
    .MuiSelect-icon {
      width: 18px;
      height: 18px;
      top: 6px;
    }

    .MuiSelect-select {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.dayDivider {
  margin-left: 10px;
  margin-right: 4px;
}

.actionsContainer {
  flex-grow: 1;
  justify-content: flex-end;

  .filtersIcon {
    border-radius: 4px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .searchIcon {
    margin-right: 12px;
    svg {
      width: 16px;
      height: 14px;
    }
  }
  .filtersBadge {
    background: #24FFC2;
    top: 4px;
    left: 8px;
    height: 10px;
    width: 10px;
    display: none;
    &.activeFiltersBadge {
      display: block;
    }
  }
}

.calendarWrapper {
  :global {
    .rbc-calendar {
      height: calc(100vh - 190px);

      > .MuiBox-root {
        background-color: white;
      }
    }

    .rbc-calendar * {
      font-family: 'Fielmann-Sans', sans-serif;
    }

    .rbc-time-slot {
      min-height: 50px !important;
      z-index: 1 !important;
    }

    .rbc-time-view {
      height: 100%;
    }

    .rbc-time-view-resources {
      .rbc-day-slot {
        min-width: 284px;

        .rbc-time-slot {
          border-top: none;
        }
      }

      .rbc-header {
        width: 284px;
      }

      .rbc-day-bg {
        width: 284px;
      }

      .rbc-time-gutter {
        z-index: 15;
      }
    }

    .rbc-time-content {
      background-color: #F6F6F8;
      border-top: none;
    }

    .rbc-allday-cell {
      display: none;
    }

    .rbc-today {
      background: transparent;
    }

    .rbc-events-container {
      margin-right: 0;
      overflow: hidden;
    }

    .rbc-time-slot {
      min-height: 68px;
    }

    .rbc-time-gutter .rbc-timeslot-group {
      display: block;

      .rbc-time-slot {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;

        .rbc-label {
          color: #000000;
          font-weight: 500;
          font-size: 18px;
        }
      }

      .rbc-time-slot:nth-child(2) {
        display: none;
      }
    }

    .rbc-day-slot:nth-child(2) {
      .rbc-current-time-indicator::before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #E32B1B;
        position: absolute;
        top: -6px;
        left: -1px;
      }
    }

    .rbc-current-time-indicator {
      border: 1px solid #E32B1B;
      z-index: 12;
    }

    .rbc-time-header-content > .rbc-row.rbc-row-resource {
      border-bottom: none;
      height: 100%;
    }

    .rbc-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: black;
      font-weight: 700;
      font-size: 14px;
      background: #FFFFFF;
      height: unset;
      padding: 0;
      border-bottom: 1px solid #DEDCD7;

      > p {
        font-weight: 700;
        font-size: 14px;
      }
    }

    .rbc-time-header-gutter {
      padding: 0;
      min-width: 101px !important;
    }
  }

  &.withoutCurrentTime {
    :global {
      .rbc-current-time-indicator {
        display: none;
      }
    }
  }

  &.singleTimeslot {
    :global {
      .rbc-timeslot-group {
        min-height: 41px;

        .rbc-time-slot {
          height: 40px;
        }
      }
    }
  }

  &.threeTimeslots {
    :global {
      .rbc-time-slot {
        min-height: 30px !important;
        z-index: 1 !important;
      }

      .rbc-time-gutter .rbc-timeslot-group {
        .rbc-time-slot {
          height: 60px;
        }

        .rbc-time-slot:nth-child(3) {
          display: none;
        }
      }
    }
  }

  &.sixTimeslots {
    :global {
      .rbc-time-gutter .rbc-timeslot-group {
        .rbc-time-slot {
          display: none;
        }

        .rbc-time-slot:nth-child(1) {
          display: flex;
          height: 150px;
        }
      }
    }
  }
}

@media all and (max-device-width: 1024px) and (orientation:landscape) {
  .calendarWrapper {
    :global {
      .rbc-calendar {
        height: calc(100dvh - 190px);
      }
    }
  }
}

