.container {
  height: 40px;
  padding: 10px 18px;
  box-sizing: border-box;
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.item {
  flex-direction: row !important;
  justify-content: flex-start;
  align-items: center;
  margin-right: 36px;

  .icon {
    margin-right: 12px;
    height: 22px !important;
    width: 22px !important;
  }

  .text {
    color: #000;
    font-family: 'Fielmann-Sans';
    font-size: 12px;
    font-weight: 700;
    line-height: 26px; /* 216.667% */
  }
}

.appointment {
  .icon {
    path {
      fill: #00567c;
    }

    path[fill] {
      fill: #00567c;
      stroke: none;
    }

    path[stroke] {
      fill: none;
      stroke: #00567c;
    }
  }
}

.walkin {
  .icon {
    path[fill] {
      fill: #0059bf !important;
      stroke: none !important;
    }

    path[stroke] {
      fill: none !important;
      stroke: #0059bf !important;
    }
  }
}

.break {
  .icon {
    path[fill] {
      fill: #838386 !important;
      stroke: none !important;
    }

    path[stroke] {
      fill: none !important;
      stroke: #838386 !important;
    }
  }
}

.blocker {
  .icon {
    path[fill] {
      fill: #776961 !important;
      stroke: none !important;
    }

    path[stroke] {
      fill: none !important;
      stroke: #776961 !important;
    }
  }
}

