.dayEventRoot {
  border-radius: 4px;
  height: 100%;
  width: calc(100% - 10px);
  padding: 7px 5px;
  margin: 0 5px;

  &.dayEventRootCompact {
    padding: 3px 5px;
  }

  &.dayEventRootDense {
    padding: 0px 5px;
  }

  .dayEventContent {
    height: 100%;
  }

  .dayEventBody {
    overflow: hidden;
    height: 100%;

    &.dayEventBodyDense {
      height: 14px;
      margin-top: 3px;
    }
  }
}

.dayEventWorkingHours {
  background-color: #FFFFFF;
  margin: unset;
  width: 100%;

  &:before {
    content: '';
    height: 4px;
    width: 100%;
    opacity: 0.8;
    border-radius: 4px 4px 0 0;
    border: 2px solid #494848;
    border-bottom: none;
    position: absolute;
    display: block;
    left: 0;
    top: 0;
  }

  &:after {
    content: '';
    height: 4px;
    width: 100%;
    opacity: 0.8;
    border-radius: 0 0 4px 4px;
    border: 2px solid #494848;
    border-top: none;
    position: absolute;
    display: block;
    left: 0;
    bottom: 0;
  }
}

.dayEventAppointment {
  border-width: 1px;
  border-style: solid;
}

.dayEventSlot {
  background-color: rgba(244, 244, 244, 0.4);
  border-width: 1px;
  border-style: solid;
}

.dayEventBreak {
  background-color: #FFFFFF;
  border: 1px rgba(157, 157, 157, 0.5) solid;
}

.dayEventBlocker {
  background-color: #FFFFFF;
  border: 1px rgba(157, 157, 157, 0.5) solid;
}

.dayEventReservation {
  opacity: 0.3;
  background-color: #E9E8E8;
  border: none;
}

.dayEventWalkin {
  background-color: #FFFFFF;
  border: none;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    border: 5px dashed #5FA8D3;
    top: -3.5px;
    bottom: -3.5px;
    left: -3.5px;
    right: -3.5px;
    border-radius: 6px;
  }
}

.dayEventLastMinute {
  background-color: #FFFFFF;
  border: none;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    border: 5px dashed #5FA8D3;
    top: -3.5px;
    bottom: -3.5px;
    left: -3.5px;
    right: -3.5px;
    border-radius: 6px;
  }
}

.dayEventServicesItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  width: 24px;
  height: 24px;
  background-color: rgba(133, 133, 133, 0.15);
  svg {
    height: 20px;
    width: 20px;
  }

  &.dayEventServicesItemDense {
    width: 20px;
    height: 20px;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.dayEventBodyText {
  font-size: 12px;
  line-height: 8px;
  white-space: nowrap;
  font-family: Fielmann-Sans !important;

  &.dayEventBodyTextNoWrap {
    text-overflow: ellipsis;
    overflow-x: clip;
    min-width: 0;
  }

  &.dayEventBodyTextBold {
    font-weight: 700;
  }

  &.dayEventBodyTextUppercase {
    text-transform: uppercase;
  }

  &.dayEventBodyTextSecondary {
    opacity: 0.5;
  }

  &.dayEventBodyTextFullWidth {
    flex: 1;
  }

  &.dayEventTimeRange {
    letter-spacing: -0.02em;
  }
}

.dayEventIndicator {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgba(133, 133, 133, 0.15);
  flex-shrink: 0;
}

.dayEventPinnedIconRoot {
  height: 8px;
  width: 8px;
  line-height: 6px;

  svg {
    position: relative;
    height: 8px;
    width: 8px;
  }
}
